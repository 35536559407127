.menu-button {
  height: 10rem;
  width: 10rem;
  border-radius: 50%;
  background: transparent;
  border: none;
  outline: none;
  text-align: center;
  cursor: pointer;
  top: 3rem;
  right: 6rem;
  z-index: 99;
  -webkit-tap-highlight-color: transparent;
  transform: rotate(180deg);

  .icon-container {
    position: absolute;
    top: 5rem;
    left: 0;
  }

  .menu-icon {
    position: absolute;
    width: 2.5rem;
    height: 0.2rem;
    background-color: white;
    display: inline-block;
    transition: all 0.2s;
    border-radius: 1px;

    // &,
    // &::before,
    // &::after {
    //   height: 0.3rem;
    //   background-color: white;
    //   display: inline-block;
    //   transition: all 0.2s;
    // }

    // &::before,
    // &::after {
    //   width: 3rem;
    //   content: "";
    //   position: absolute;
    //   left: 0;
    // }

    // &::before {
    //   top: -0.8rem;
    // }
    // &::after {
    //   top: 0.8rem;
    // }
  }

  .line-1,
  .line-3 {
    width: 3rem;
  }

  .line-1 {
    top: -0.8rem;
  }

  .line-2 {
    top: 0;
  }

  .line-3 {
    top: 0.8rem;
  }

  //   .line-1 {
  //     top: -0.8rem;
  //   }

  //   .line-3 {
  //     top: 0.8rem;
  //   }

  &:hover .line-3 {
    animation: fullWidthChange 0.15s linear;
  }

  &:hover .line-2 {
    animation: halfWidthChange 0.125s linear 0.05s;
  }

  &:hover .line-1 {
    animation: fullWidthChange 0.15s linear 0.1s;
  }
}

.checkbox {
  width: 0;
  display: none;
  visibility: hidden;
}

.checkbox:checked + .menu-button .line-2 {
  background: transparent;
}

.checkbox:checked + .menu-button .line-1 {
  top: 0;
  transform: rotate(135deg);
}

.checkbox:checked + .menu-button .line-3 {
  top: 0;
  transform: rotate(-135deg);
}

.menu {
  width: 100%;
  height: 100vh;
  opacity: 0;
  visibility: hidden;
  margin: 0;
  position: absolute;
  z-index: 90;
  transition: all 0.2s;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.visible {
  background: #1c2131;
  opacity: 1;
  visibility: visible;
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
}

.menu-items {
  width: 40%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;

  .menu-option {
    width: 100%;
    text-decoration: none;
    text-transform: uppercase;
    border-bottom: 1px solid white;
    color: white;
    font-family: poppins;
    font-size: 3rem;
    letter-spacing: 3px;
    background-size: 100% 200%;
    background-image: linear-gradient(to top, white 50%, #1c2131 50%);
    transition: all 0.5s;

    &:hover,
    &:active {
      background-position: 0 100%;
      color: #1c2131;
    }

    &:not(:last-child) {
      margin-bottom: 5rem;
    }
  }
}

.menu-details {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 30%;
  color: white;

  .logo-container{
    width: 50%;

    img{
      width: 100%;
      object-fit: contain;
      object-position: center;
    }
  }

  .text {
    font-family: roboto;
    font-size: 2rem;
    letter-spacing: 1.5px;
    line-height: 2.4rem;
    text-align: center;
    margin-bottom: 5rem;
  }

  .links {
    width: 100%;
    margin: 5rem auto 0 auto;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    font-size: 2rem;

    .link {
      color: white;
      &:hover {
        color: #5870c9;
        transform: scale(1.3);
      }
    }
  }
}

@keyframes fullWidthChange {
  0% {
    width: 3rem;
  }
  50% {
    width: 0;
  }
  100% {
    width: 3rem;
  }
}

@keyframes halfWidthChange {
  0% {
    width: 2.5rem;
  }
  50% {
    width: 0;
  }
  100% {
    width: 2.5rem;
  }
}

@media only screen and (max-width: 470px) {
  .menu-button {
    right: 3rem;
  }

  .menu {
    flex-direction: column;
    justify-content: space-evenly;
    padding: 2rem 0;
  }

  .menu-items {
    width: 60%;
    .menu-option {
      font-size: 2rem;
      letter-spacing: 2px;
    }
  }

  .menu-details {
    width: 50%;

    .logo-container{
      width: 75%;
    }

    .text{
      display: none;
    }
  }
}
