*,
body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  height: 100%;
  font-size: 57%;
}

body {
  height: 100%;
}

body::-webkit-scrollbar {
  width: 7.5px;
  background-color: transparent;
}

body::-webkit-scrollbar-thumb {
  background-color: #142668;
  border-radius: 10px;
}

.scroll {
  padding: 0 3rem;
  font-size: 1.6rem;
  font-family: poppins;
  letter-spacing: 2px;
  height: 10rem;
}

.scroll-dark {
  color: #142668;
  border-color: #142668;
}

.scroll-light {
  color: white;
  border-color: white;
}

.scroll-buttom-right {
  position: absolute;
  right: 6rem;
  bottom: 0;
  border-right: 2px solid;
}

.scroll-top-right {
  position: absolute;
  right: 6rem;
  border-right: 2px solid;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.scroll-buttom-left {
  position: absolute;
  left: 6rem;
  bottom: 0;
  border-left: 2px solid;
}

.scroll-top-left {
  position: absolute;
  left: 6rem;
  border-left: 2px solid;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.emperor{
  color: #ffd700;
}

@media only screen and (max-width: 950px) {
  html {
    font-size: 57%;
  }
}

@media only screen and (max-width: 470px) {
  html {
    font-size: 50%;
  }

  .scroll {
    padding: 0 1.5rem;
    font-size: 1.4rem;
    letter-spacing: 1.8px;
    height: 8rem;
  }

  .scroll-buttom-right {
    right: 3rem;
  }

  .scroll-top-right {
    right: 3rem;
  }

  .scroll-buttom-left {
    left: 3rem;
  }

  .scroll-top-left {
    left: 3rem;
  }
}
