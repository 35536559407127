.service-card {
  width: 30%;
  height: 40rem;
  background-image: linear-gradient(rgba(0, 0, 0, 0.9));
  position: relative;
  overflow: hidden;
  transition: 0.5s;
  margin-bottom: 3rem;

  img {
    width: 110%;
    height: 110%;
    display: block;
    object-fit: cover;
    filter: brightness(0.5);
    transition: 0.5s;
  }

  .card-heading {
    position: absolute;
    text-align: right;
    right: 2rem;
    bottom: 0;
    color: white;
    font-family: poppins;
    font-size: 2.8rem;
    font-weight: 500;
    letter-spacing: 3px;
    z-index: 1;
    transition: 0.5s;
  }

  .card-content {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    transition: 0.5s;
    opacity: 0;

    .text {
      width: 100%;
      height: 100%;
      transition: 0.5s;
      background: rgba(221, 221, 221, 0.8);
      display: flex;
      align-items: center;
      color: black;
      white-space: pre-line;
      font-family: roboto;
      font-size: 2rem;
      text-align: center;
      padding: 2rem auto;
    }
  }
}

@media only screen and (max-width: 950px) {
  .service-card{
    width: 45%;
  }
}

@media only screen and (max-width: 650px) {
  .service-card{
    width: 100%;
  }
}

@media only screen and (max-width: 470px) {
  .service-card{
    width: 100%;
    margin-bottom: 5rem;
    border-radius: 2px;
  }
}

@media(hover: hover){
  .service-card{
    &:hover {
      img {
        width: 100%;
        height: 100%;
      }
      .card-heading {
        transform: translateY(-3rem);
      }
      // .card-content {
      //   opacity: 1;
      // }
    }
  }
}

@media(hover: none){
  .service-card{
    img {
      width: 100%;
      height: 100%;
    }
    .card-heading {
      transform: translateY(-3rem);
    }
  }
}
