.service-page {
  height: 100vh;
  position: relative;

  .scroll {
    height: 80vh;
  }

  .logo-container {
    position: absolute;
    left: 6rem;
    top: 3rem;
    width: 10rem;

    img {
      width: 100%;
      position: relative;
      z-index: 1;
    }
  }
  .menu-button {
    .menu-icon {
      background-color: black;
    }
  }

  .checkbox:checked + .menu-button .line-1,
  .checkbox:checked + .menu-button .line-3 {
    background: white;
  }

  .content {
    width: 80%;
    height: 100%;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: space-around;
    position: relative;

    .images-container {
      width: 40%;
      position: relative;

      img {
        width: 100%;
        object-fit: cover;
        display: block;
        object-fit: cover;
      }
    }

    .text {
      width: 40%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      h1 {
        font-size: 3rem;
        text-transform: uppercase;
        font-family: poppins;
        letter-spacing: 3px;
        text-align: center;
        margin-bottom: 3rem;
      }

      p {
        white-space: pre-line;
        font-family: roboto;
        font-size: 2rem;
        line-height: 4rem;
      }
    }
  }

  .footer {
    position: absolute;
    bottom: 0;
    width: 100%;
  }
}

@media only screen and (max-width: 470px){
  .service-page{
    .scroll{
      height: 10vh;
    }

    .content{
      flex-direction: column;
      justify-content: center;

      .images-container{
        width: 90%;
        margin-bottom: 5rem;
      }

      .text{
        width: 90%;
      }
    }
  }
}