.contact-us-section {
  background-image: linear-gradient(
      to right bottom,
      rgba(rgb(61, 70, 102), 0.9),
      rgba(rgb(0, 0, 0), 0.9)
    ),
    url("../../Assets/ContactUsSection/background.jpg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: white;
  overflow: auto;

  .heading {
    font-family: poppins;
    font-size: 4rem;
    font-weight: 600;
    letter-spacing: 5px;
    text-align: center;
    padding-top: 15rem;
  }

  .underline {
    width: 30%;
    height: 1px;
    border-bottom: 1px solid white;
    margin: 2.5rem auto;
  }

  .content {
    width: 70%;
    margin: 10rem auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-family: roboto;

    h3 {
      font-size: 2.4rem;
      line-height: 3rem;
      letter-spacing: 3px;
      margin-bottom: 1rem;
      color: gray;
    }

    p {
      font-size: 1.8rem;
      line-height: 2rem;
      letter-spacing: 2px;
    }

    .mail {
      margin-bottom: 3rem;
    }
  }

  .links {
    width: 30%;
    margin: 5rem auto;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    font-size: 2rem;

    .link {
      color: white;
      &:hover {
        color: #5870c9;
        transform: scale(1.3);
      }
    }
  }
}

.footer {
  background-color: #1c2131;
  color: white;
  text-align: center;
  font-size: 1.6rem;
  padding: 5px 10px;

  a{
    color: white;
    text-decoration: none;

  }
}

@media only screen and (max-width: 950px) {
  .contact-us-section {
    .content {
      width: 80%;
    }
  }
}

@media only screen and (max-width: 768px) {
  .contact-us-section {
    .content {
      width: 80%;
      flex-direction: column;
      text-align: center;
      .address {
        margin-bottom: 3rem;
      }
    }
  }
}

@media only screen and (max-width: 470px) {
  .contact-us-section {
    .heading {
      padding-top: 12rem;
      font-size: 3.5rem;
      letter-spacing: 4px;
    }
    .underline {
      width: 50%;
      margin: 3rem auto;
    }
    .links {
      width: 50%;
    }
  }
}
