.landing-section {
  width: 100%;
  min-height: 100vh;
  background-image: linear-gradient(
      to right bottom,
      rgba(rgb(0, 0, 0), 0.95) 10%,
      rgba(rgb(37, 52, 106), 0.5)
    ),
    url("../../Assets/LandingSection/background.jpg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  animation: bgChange;
  animation-duration: 5s;
  animation-timing-function: cubic-bezier(1,0,0,1);
  animation-iteration-count: infinite;
  animation-direction: alternate;

  .logo-container {
    position: absolute;
    left: 6rem;
    top: 3rem;
    width: 10rem;

    img {
      width: 100%;
      position: relative;
      z-index: 1;
    }
    span {
      color: white;
      text-transform: uppercase;
      font-size: 3rem;
      letter-spacing: 3px;
      font-family: "Bebas Neue", cursive;
      position: absolute;
      top: 50%;
      z-index: 0;
      margin: -2rem 0.5rem;
    }
  }

  .text {
    width: 50%;

    .welcome {
      color: rgb(162, 162, 162);
      font-size: 2.5rem;
      font-family: poppins;
      font-weight: 600;
      letter-spacing: 5px;
      margin-bottom: 1rem;
    }

    .heading {
      color: white;
      font-size: 4.5rem;
      font-family: roboto;
      font-weight: 800;
      letter-spacing: 4px;
    }

    .button {
      text-decoration: none;
      background-color: transparent;
      border: 1px solid white;
      outline: none;
      padding: 1rem 2rem;
      color: white;
      font-size: 2rem;
      font-family: poppins;
      font-weight: 500;
      letter-spacing: 3px;
      margin: 4rem 0;
      cursor: pointer;
      transition: 0.5s;

      &:hover,
      &:active {
        background: white;
        color: black;
      }
    }
  }

  .scroll {
    cursor: pointer;

    .scroll-icon {
      margin-right: 5px;

      svg {
        vertical-align: middle;
      }
    }
  }
}

@keyframes bgChange {
  0%{
    background-image: linear-gradient(
      to right bottom,
      rgba(rgb(0, 0, 0), 0.95) 10%,
      rgba(rgb(37, 52, 106), 0.5)
    ),
    url("../../Assets/LandingSection/background.jpg");
  }
  25%{
    background-image: linear-gradient(
      to right bottom,
      rgba(rgb(0, 0, 0), 0.95) 10%,
      rgba(rgb(37, 52, 106), 0.5)
    ),
    url("../../Assets/LandingSection/background.jpg");
  }
  26%{
    background-image: linear-gradient(
      to right bottom,
      rgba(rgb(0, 0, 0), 0.952) 10%,
      rgba(rgb(37, 52, 106), 0.52)
    ),
    url("../../Assets/LandingSection/background.jpg");
  }
  27%{
    background-image: linear-gradient(
      to right bottom,
      rgba(rgb(0, 0, 0), 0.954) 10%,
      rgba(rgb(37, 52, 106), 0.54)
    ),
    url("../../Assets/LandingSection/background.jpg");
  }
  28%{
    background-image: linear-gradient(
      to right bottom,
      rgba(rgb(0, 0, 0), 0.956) 10%,
      rgba(rgb(37, 52, 106), 0.56)
    ),
    url("../../Assets/LandingSection/background.jpg");
  }
  29%{
    background-image: linear-gradient(
      to right bottom,
      rgba(rgb(0, 0, 0), 0.958) 10%,
      rgba(rgb(37, 52, 106), 0.58)
    ),
    url("../../Assets/LandingSection/background.jpg");
  }
  30%{
    background-image: linear-gradient(
      to right bottom,
      rgba(rgb(0, 0, 0), 0.96) 10%,
      rgba(rgb(37, 52, 106), 0.6)
    ),
    url("../../Assets/LandingSection/background.jpg");
  }
  31%{
    background-image: linear-gradient(
      to right bottom,
      rgba(rgb(0, 0, 0), 0.962) 10%,
      rgba(rgb(37, 52, 106), 0.62)
    ),
    url("../../Assets/LandingSection/background.jpg");
  }
  32%{
    background-image: linear-gradient(
      to right bottom,
      rgba(rgb(0, 0, 0), 0.964) 10%,
      rgba(rgb(37, 52, 106), 0.64)
    ),
    url("../../Assets/LandingSection/background.jpg");
  }
  33%{
    background-image: linear-gradient(
      to right bottom,
      rgba(rgb(0, 0, 0), 0.966) 10%,
      rgba(rgb(37, 52, 106), 0.66)
    ),
    url("../../Assets/LandingSection/background.jpg");
  }
  34%{
    background-image: linear-gradient(
      to right bottom,
      rgba(rgb(0, 0, 0), 0.968) 10%,
      rgba(rgb(37, 52, 106), 0.68)
    ),
    url("../../Assets/LandingSection/background.jpg");
  }
  35%{
    background-image: linear-gradient(
      to right bottom,
      rgba(rgb(0, 0, 0), 0.97) 10%,
      rgba(rgb(37, 52, 106), 0.7)
    ),
    url("../../Assets/LandingSection/background.jpg");
  }
  36%{
    background-image: linear-gradient(
      to right bottom,
      rgba(rgb(0, 0, 0), 0.972) 10%,
      rgba(rgb(37, 52, 106), 0.72)
    ),
    url("../../Assets/LandingSection/background.jpg");
  }
  37%{
    background-image: linear-gradient(
      to right bottom,
      rgba(rgb(0, 0, 0), 0.974) 10%,
      rgba(rgb(37, 52, 106), 0.74)
    ),
    url("../../Assets/LandingSection/background.jpg");
  }
  38%{
    background-image: linear-gradient(
      to right bottom,
      rgba(rgb(0, 0, 0), 0.976) 10%,
      rgba(rgb(37, 52, 106), 0.76)
    ),
    url("../../Assets/LandingSection/background.jpg");
  }
  39%{
    background-image: linear-gradient(
      to right bottom,
      rgba(rgb(0, 0, 0), 0.978) 10%,
      rgba(rgb(37, 52, 106), 0.78)
    ),
    url("../../Assets/LandingSection/background.jpg");
  }
  40%{
    background-image: linear-gradient(
      to right bottom,
      rgba(rgb(0, 0, 0), 0.98) 10%,
      rgba(rgb(37, 52, 106), 0.8)
    ),
    url("../../Assets/LandingSection/background.jpg");
  }
  41%{
    background-image: linear-gradient(
      to right bottom,
      rgba(rgb(0, 0, 0), 0.982) 10%,
      rgba(rgb(37, 52, 106), 0.82)
    ),
    url("../../Assets/LandingSection/background.jpg");
  }
  42%{
    background-image: linear-gradient(
      to right bottom,
      rgba(rgb(0, 0, 0), 0.984) 10%,
      rgba(rgb(37, 52, 106), 0.84)
    ),
    url("../../Assets/LandingSection/background.jpg");
  }
  43%{
    background-image: linear-gradient(
      to right bottom,
      rgba(rgb(0, 0, 0), 0.986) 10%,
      rgba(rgb(37, 52, 106), 0.86)
    ),
    url("../../Assets/LandingSection/background.jpg");
  }
  44%{
    background-image: linear-gradient(
      to right bottom,
      rgba(rgb(0, 0, 0), 0.988) 10%,
      rgba(rgb(37, 52, 106), 0.88)
    ),
    url("../../Assets/LandingSection/background.jpg");
  }
  45%{
    background-image: linear-gradient(
      to right bottom,
      rgba(rgb(0, 0, 0), 0.99) 10%,
      rgba(rgb(37, 52, 106), 0.9)
    ),
    url("../../Assets/LandingSection/background.jpg");
  }
  46%{
    background-image: linear-gradient(
      to right bottom,
      rgba(rgb(0, 0, 0), 0.992) 10%,
      rgba(rgb(37, 52, 106), 0.92)
    ),
    url("../../Assets/LandingSection/background.jpg");
  }
  47%{
    background-image: linear-gradient(
      to right bottom,
      rgba(rgb(0, 0, 0), 0.994) 10%,
      rgba(rgb(37, 52, 106), 0.94)
    ),
    url("../../Assets/LandingSection/background.jpg");
  }
  48%{
    background-image: linear-gradient(
      to right bottom,
      rgba(rgb(0, 0, 0), 0.996) 10%,
      rgba(rgb(37, 52, 106), 0.96)
    ),
    url("../../Assets/LandingSection/background.jpg");
  }
  49%{
    background-image: linear-gradient(
      to right bottom,
      rgba(rgb(0, 0, 0), 0.998) 10%,
      rgba(rgb(37, 52, 106), 0.98)
    ),
    url("../../Assets/LandingSection/background.jpg");
  }
  
  50%{
    background-image: linear-gradient(
      to right bottom,
      rgba(rgb(0, 0, 0), 1) 10%,
      rgba(rgb(37, 52, 106), 1)
    ),
    url("../../Assets/LandingSection/background.jpg");
  }
  51%{
    background-image: linear-gradient(
      to right bottom,
      rgba(rgb(0, 0, 0), 1) 10%,
      rgba(rgb(37, 52, 106), 1)
    ),
    url("../../Assets/LandingSection/background2.jpg");
  }
  52%{
    background-image: linear-gradient(
      to right bottom,
      rgba(rgb(0, 0, 0), 0.998) 10%,
      rgba(rgb(37, 52, 106), 0.98)
    ),
    url("../../Assets/LandingSection/background2.jpg");
  }
  53%{
    background-image: linear-gradient(
      to right bottom,
      rgba(rgb(0, 0, 0), 0.996) 10%,
      rgba(rgb(37, 52, 106), 0.96)
    ),
    url("../../Assets/LandingSection/background2.jpg");
  }
  54%{
    background-image: linear-gradient(
      to right bottom,
      rgba(rgb(0, 0, 0), 0.994) 10%,
      rgba(rgb(37, 52, 106), 0.94)
    ),
    url("../../Assets/LandingSection/background2.jpg");
  }
  55%{
    background-image: linear-gradient(
      to right bottom,
      rgba(rgb(0, 0, 0), 0.992) 10%,
      rgba(rgb(37, 52, 106), 0.92)
    ),
    url("../../Assets/LandingSection/background2.jpg");
  }
  56%{
    background-image: linear-gradient(
      to right bottom,
      rgba(rgb(0, 0, 0), 0.989) 10%,
      rgba(rgb(37, 52, 106), 0.89)
    ),
    url("../../Assets/LandingSection/background2.jpg");
  }
  57%{
    background-image: linear-gradient(
      to right bottom,
      rgba(rgb(0, 0, 0), 0.986) 10%,
      rgba(rgb(37, 52, 106), 0.86)
    ),
    url("../../Assets/LandingSection/background2.jpg");
  }
  58%{
    background-image: linear-gradient(
      to right bottom,
      rgba(rgb(0, 0, 0), 0.984) 10%,
      rgba(rgb(37, 52, 106), 0.84)
    ),
    url("../../Assets/LandingSection/background2.jpg");
  }
  59%{
    background-image: linear-gradient(
      to right bottom,
      rgba(rgb(0, 0, 0), 0.982) 10%,
      rgba(rgb(37, 52, 106), 0.82)
    ),
    url("../../Assets/LandingSection/background2.jpg");
  }
  60%{
    background-image: linear-gradient(
      to right bottom,
      rgba(rgb(0, 0, 0), 0.98) 10%,
      rgba(rgb(37, 52, 106), 0.8)
    ),
    url("../../Assets/LandingSection/background2.jpg");
  }
  61%{
    background-image: linear-gradient(
      to right bottom,
      rgba(rgb(0, 0, 0), 0.978) 10%,
      rgba(rgb(37, 52, 106), 0.78)
    ),
    url("../../Assets/LandingSection/background2.jpg");
  }
  62%{
    background-image: linear-gradient(
      to right bottom,
      rgba(rgb(0, 0, 0), 0.976) 10%,
      rgba(rgb(37, 52, 106), 0.76)
    ),
    url("../../Assets/LandingSection/background2.jpg");
  }
  63%{
    background-image: linear-gradient(
      to right bottom,
      rgba(rgb(0, 0, 0), 0.974) 10%,
      rgba(rgb(37, 52, 106), 0.74)
    ),
    url("../../Assets/LandingSection/background2.jpg");
  }
  64%{
    background-image: linear-gradient(
      to right bottom,
      rgba(rgb(0, 0, 0), 0.972) 10%,
      rgba(rgb(37, 52, 106), 0.72)
    ),
    url("../../Assets/LandingSection/background2.jpg");
  }
  65%{
    background-image: linear-gradient(
      to right bottom,
      rgba(rgb(0, 0, 0), 0.97) 10%,
      rgba(rgb(37, 52, 106), 0.7)
    ),
    url("../../Assets/LandingSection/background2.jpg");
  }
  66%{
    background-image: linear-gradient(
      to right bottom,
      rgba(rgb(0, 0, 0), 0.968) 10%,
      rgba(rgb(37, 52, 106), 0.68)
    ),
    url("../../Assets/LandingSection/background2.jpg");
  }
  67%{
    background-image: linear-gradient(
      to right bottom,
      rgba(rgb(0, 0, 0), 0.966) 10%,
      rgba(rgb(37, 52, 106), 0.66)
    ),
    url("../../Assets/LandingSection/background2.jpg");
  }
  68%{
    background-image: linear-gradient(
      to right bottom,
      rgba(rgb(0, 0, 0), 0.964) 10%,
      rgba(rgb(37, 52, 106), 0.64)
    ),
    url("../../Assets/LandingSection/background2.jpg");
  }
  69%{
    background-image: linear-gradient(
      to right bottom,
      rgba(rgb(0, 0, 0), 0.962) 10%,
      rgba(rgb(37, 52, 106), 0.62)
    ),
    url("../../Assets/LandingSection/background2.jpg");
  }
  70%{
    background-image: linear-gradient(
      to right bottom,
      rgba(rgb(0, 0, 0), 0.96) 10%,
      rgba(rgb(37, 52, 106), 0.6)
    ),
    url("../../Assets/LandingSection/background2.jpg");
  }
  71%{
    background-image: linear-gradient(
      to right bottom,
      rgba(rgb(0, 0, 0), 0.958) 10%,
      rgba(rgb(37, 52, 106), 0.58)
    ),
    url("../../Assets/LandingSection/background2.jpg");
  }
  72%{
    background-image: linear-gradient(
      to right bottom,
      rgba(rgb(0, 0, 0), 0.956) 10%,
      rgba(rgb(37, 52, 106), 0.56)
    ),
    url("../../Assets/LandingSection/background2.jpg");
  }
  73%{
    background-image: linear-gradient(
      to right bottom,
      rgba(rgb(0, 0, 0), 0.954) 10%,
      rgba(rgb(37, 52, 106), 0.54)
    ),
    url("../../Assets/LandingSection/background2.jpg");
  }
  74%{
    background-image: linear-gradient(
      to right bottom,
      rgba(rgb(0, 0, 0), 0.952) 10%,
      rgba(rgb(37, 52, 106), 0.52)
    ),
    url("../../Assets/LandingSection/background2.jpg");
  }
  75%{
    background-image: linear-gradient(
      to right bottom,
      rgba(rgb(0, 0, 0), 0.95) 10%,
      rgba(rgb(37, 52, 106), 0.5)
    ),
    url("../../Assets/LandingSection/background2.jpg");
  }
  100%{
    background-image: linear-gradient(
      to right bottom,
      rgba(rgb(0, 0, 0), 0.95) 10%,
      rgba(rgb(37, 52, 106), 0.5)
    ),
    url("../../Assets/LandingSection/background2.jpg");
  }
}

@media only screen and (max-width: 1000px) {
  .landing-section {
    .text {
      width: 60%;
    }
  }
}

@media only screen and (max-width: 768px) {
  .landing-section {
    .text {
      width: 65%;
      text-align: center;
      .welcome {
        font-size: 2rem;
        letter-spacing: 4px;
      }
      .heading {
        font-size: 3.5rem;
        letter-spacing: 3.5px;
      }
    }
  }
}

@media only screen and (max-width: 470px) {
  .landing-section {
    .logo-container {
      left: 3rem;
    }
    .text {
      width: 70%;
      .welcome {
        font-size: 2rem;
        letter-spacing: 4px;
      }
      .heading {
        font-size: 3rem;
        letter-spacing: 3px;
      }
    }
  }
}
