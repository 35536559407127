.map-section {
  position: relative;
  color: #142668;

  .heading {
    font-family: poppins;
    font-size: 4rem;
    font-weight: 600;
    letter-spacing: 5px;
    text-align: center;
    padding-top: 15rem;
  }

  .underline {
    width: 30%;
    height: 1px;
    border-bottom: 1px solid #142668;
    margin: 2.5rem auto;
  }

  .map-container {
    width: 70%;
    margin: auto;
    height: 50rem;
    padding-bottom: 15rem;

    .leaflet-container {
      height: 100%;
      width: 100%;
    }
  }
}

@media only screen and (max-width: 470px) {
  .map-section {
    .heading {
      font-size: 3.5rem;
      letter-spacing: 4px;
      padding-top: 12rem;
    }
    .underline {
      width: 50%;
      margin: 3rem auto;
    }
    .map-container {
      width: 80%;
    }
  }
}

@media only screen and (max-width: 768px) {
  .map-section {
    .map-container {
      width: 75%;
    }
    .underline {
      margin: 3.5rem auto;
    }
  }
}
